.cardsContainer{
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 90%;
}

.card{
    margin: 1px;
    width: 375px;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center; 
}

.card-body{
    position: absolute;
    width: 100%;
    min-height: 100%;
}

.noCardDisplay{
    display: none;
}

.card-img-top{
    transition: 0.4s;
    min-height: 350px;
}

.card-body .card-title  {
    opacity: 0;
}

.card-body .buttonDisplay {
    opacity: 0;
}

.card-title{
    margin-top: 55px;
    padding: 20px;
    font-size: 25pt;
    font-weight: 600;
    color: black;
}

.card-text{
    font-size: 16pt;
    color: #0d6efd;
}

.card-body:hover .card-title{
    opacity: 1;
    animation: titleAnimation 0.3s ease-in;   
}

.card:hover .card-img-top{
    opacity: 0;
}

.card:hover{
    border: none;
}

.card-body:hover .buttonDisplay{
    opacity: 1; 
    animation: buttonAnimation 0.3s ease-in;    
}

@keyframes titleAnimation {
    from {transform: translateY(-50px);}
    to {transform: translateY(0px);}  
}

@keyframes buttonAnimation {
    from {transform: translateY(50px);}
    to {transform: translateY(0px);}  
}