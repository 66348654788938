#hexagon {
  height: 130px;
  width: 130px;
  background: #0d6efd;
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  display: inline-block;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.flex-container {
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.bullet-wrap {
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 32pt;
  line-height: 36pt;
  align-content: center;
}

.bi-speedometer2 {
  padding: 10px;
}

.spanFont {
  font-size: 20pt;
  font-weight: bolder;
  color: #616161;
}

.listHeading{
  font-size: 20pt;
  font-weight: bolder;
  color: #616161;
  margin-bottom: 12px;
  text-align: center;
}

#paragraphFont {
  margin: 2px;
  padding: 5px;
  font-size: 14pt;
  color: #616161;
  word-wrap: break-word;
  font-weight: 500;
  width: 65%;
  line-height: 18pt;
}

#resumeParagraph{
  margin: 2px;
  padding: 5px;
  font-size: 14pt;
  color: #616161;
  word-wrap: break-word;
  font-weight: 500;
  /* width: 65%; */
  line-height: 18pt;
  margin-top: 18px;
}

.centerIcons {
  padding: 35px;
  color: white;
  font-size: 3.5rem;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  font-size: 30px;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 10rem;
}

.flex-section-left {
  padding: 10px;
  flex: 50%;
}

.flex-section-right {
  padding: 10px;
  flex: 50%;
  margin-top: -10px;
}

.imagePosition {
  width: 40%;
  border: 2px solid;
}

.progress{
  width: 100%;
  height: 1.5rem;
  background-color: transparent;
}

#java{
  width: 100%;
}

#selenium{
  width: 75%;
}

#react{
  width: 60%;
}

#testcafe{
  width: 60%;
}

#jenkins{
  width: 60%;
}

#html{
  width: 61%;
}

#css{
  width: 64%;
}

#dedication{
  width: 100%;
}

#gitlab{
  width: 65%;
}

#jira{
  width: 85%;
}

.textListBgColor{
  background: #616161;
  font-size: 16px;
  color: white;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  /* width: 100%;
  max-width: 125px; */
  position: relative;
}



.headingSpace{
  margin-top: 40px;
  padding-top: 20px;
}

.characterContainer{
  margin-bottom: 10rem;
}

.wraparound{
  margin-top: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 32pt;
  line-height: 36pt;
  align-content: center;
}

.bi-download::before{
  padding: 5px;
  margin-right: 10px;
}

#downloadBtn{
  width: 300px;
  border-radius: 0px;
  font-size: 16pt;
  font-weight: bold;
  background-color: #0d6efd;
}

@media (max-width: 640px) {
  .flex-container {
    justify-content: center;
    flex: 100%;
  }
}

@media (max-width: 800px) {
  .flex-section-right,
  .flex-section-left {
    flex: 100%;
  }
}

@media (max-width: 400px) {
  .imagePosition {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: auto;
  }
}


