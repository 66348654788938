#buttonGroup{
    border: none;
    /* margin-left: 65px;
    margin-right: 65px; */
    color: black;
}

.btn-group{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 0;
}