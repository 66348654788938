.flipCards{
  margin-bottom: 65px;

}

.cardOne {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
  cursor: pointer;
  display: inline-flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.cardOne img {
  width: 300px;
  height: 300px;
}

.card-containerOne {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.cardOne:hover .card-containerOne {
  transform: rotateY(180deg);
}

.card-frontOne,
.card-backOne {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-frontOne {
  background-color: #bbb;
  color: black;
}

.card-backOne {
  background-color: #616161;
  color: white;
  transform: rotateY(180deg);
}

#fontSize{
    font-size: large;
    line-height: 25px;
    padding: 20px;
}

.characterButton{
    border-radius: 0%;
    width: 300px;
    font-size: 16pt;
    font-weight: bold;
    margin: 20px;
    padding: 5px;
}

.characterButton:hover{
    background-color: #616161;
}