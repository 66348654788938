.navbar{
    background-color: #1b242f;
    border-bottom: 3px solid #0d6efd;
    overflow: hidden;
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 1;
}

.navbar-nav{
    font-size: larger;
}

.navbar-dark .navbar-nav .nav-lin{
    margin-left: 10px;
}

.navbar-dark .navbar-nav .nav-link{
    margin-right: 25px;
    margin-left: 25px;
    color: white;
}

.navbar-dark .navbar-nav .nav-link:hover{
    color: #0d6efd;
}