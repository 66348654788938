.footer {
  background-color: #1b242f;
  height: 250px;
}

.listOfIcons{
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.s-box {
  width: 30px;
  height: 25px;
  padding: 5px;
  position: relative;
  cursor: pointer;
  transition: 300ms ease;
  background: #0e4d92;
  padding: 20px;
}
.socials a .icon {
  height: 20px;
  width: 20px;
}
.icon,
.name {
  text-align: center;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
}
.s-box .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: 250ms ease;
}
.s-box .name {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 250ms ease;
  width: max-content;
  font-size: 20px;
}
.s-box:hover {
  width: 150px;
}
.s-box:hover:hover .icon {
  opacity: 0;
  top: 30%;
}
.s-box:hover:hover .name {
  opacity: 1;
  top: 50%;
}

#iconSpacing{
    margin: 10px;
}

.icon{
    font-size: 24px;
}
