#contact{
    background: #252934;
    height: 1000px;
    margin-top: 6rem;
}

.headingLevelTwoPosition{
    padding-top: 5rem;
    color: white;
    font-weight: bold;
}

.paragraphFont{
    margin-top: 1rem;
    color: white;
}