.modal-header{
    border-bottom: 1px solid black;
}

.modal-footer{
    border-top: 1px solid black;
    justify-content: center;
}

.modal-body{
    font-size: large;
}

.modal-title{
    font-size: 20pt;
    line-height: 25pt;
    color: #333
}

#secondaryButton{
    border-radius: 0%;
    padding: 10px;
    font-size: 12pt;
}

#primaryButton{
    border-radius: 0%;
    padding: 10px;
    font-size: 12pt;
}