.btn-outline-primary {
  width: 10em;
  margin-top: 20px;
  border-radius: 0%;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16pt;
  border-color: white;
  border-width: medium;
  font-weight: bold;
  color: white;
}

.btn:hover .bi-chevron-double-right::before {
  transition: all ease-in-out 0.5s;
}

.bi-chevron-double-right {
  margin-left: 10px;
}

.btn-outline-primary:hover {
  background-color: #0d6efd;
}

.btn:hover .bi-chevron-double-right::before {
  transform: rotate(90deg);
}
