.btn-outline-primary-ftr {
    width: 3em;
    margin-top: 20px;
    border-radius: 0%;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16pt;
    border-color: white;
    border-width: medium;
    font-weight: bold;
    color: white;
}

#arrowIconUp{
    padding: 10px;
    color: white;
}

#footerButton{
    position: relative;
    bottom: 40px;
    background-color: #0d6efd;
    z-index: 1;
}
  
  