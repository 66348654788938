.contactFormContainer{
    padding: 20px;
    margin: auto;
    width: 50%;
    text-align: left;
}

.buttonPosition{
    display: flex;
    justify-content: right;
    margin-top: 10px;
}

.form-label{
    color: white;
}

.formErrorMessages{
    color: red;
}
  
.errorBox{
    border-color: red;
    border-width: 1px;
    color: white;
}

.form-control{
    background-color: #1e242c;
    border-color: none;
    color: white;
    border-color: transparent;
}

.mb-3{
    margin-bottom: 2px !important;
}

#contactBtn{
    background-color: transparent;
    padding: 10px;
    font-weight: 400;
    font-size: large;
    padding-left: 3rem;
    padding-right: 3rem;
    border-color: white;
    border: 2px solid white;
    border-radius: 0%;
}

#contactBtn:hover{
    background-color: #0d6efd;
}

@media (max-width: 450px) {
    .contactFormContainer {
      width: 80%;
    }
  }

