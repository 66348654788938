#home > .flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 22em;
  font-size: 32pt;
  line-height: 36pt;
  align-content: center;
}

#changeColorText {
  color: #0d6efd;
}

#indexPos{
  z-index: 1;
}

#textColor{
  color: white;
}

#home{
  position: relative;
  height: 1000px;
}